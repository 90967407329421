import api from "@/util/api";
import store from "@/store";
import router from "@/router";
import axios from 'axios';
import dayjs from 'dayjs';


const logout = async () => {
    await api.post('/auth/sign-out')
    document.cookie = 'chirp_notes_id=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    store.commit('clearUser');
    store.commit('clearGoogleTokens');
    await store.dispatch('recent/clearStore');
    await router.push({name: 'SignIn'});
    window.location.reload();
}

const keepAlive = async () => {
    await api.get('/auth/keep-alive')
}

export const timeoutFunctions = (function () {
    let instance;

    return async function () {
        if (!instance) {
            instance = {};
            instance.time = undefined;
            instance.lastRequest = dayjs()
            instance.logoutTime = dayjs()
            instance.warnTime = dayjs()

            let gettingTime = false

            // Get time if we don't have it
            if (!instance.time) {
                gettingTime = true
                const res = await axios.get(process.env.VUE_APP_API_DOMAIN + '/settings/auto_logoff_users')
                instance.time = parseInt(res.data.value) * 60 * 1000 // how long until they're logged out
                instance.lastInteraction = dayjs() // last keystroke, mouse click
                instance.lastRequest = dayjs() // last api request => this is critical as we have to keep BE in sync
                instance.logoutTime = dayjs().add(instance.time, 'milliseconds') // when we gonna log u out
                instance.warnTime = dayjs().add(instance.time - 120000, 'milliseconds') // when we'll let you know we gonna log you out
                gettingTime = false
            }

            // TIMERS
            // 1 s
            const checkTimer = setInterval(() => {
                    const now = dayjs()

                    if (store.state.user?.id && !gettingTime) {

                        if (instance.time &&
                            now.isAfter(instance.logoutTime)) {
                            logout()
                            return
                        }

                        if (instance.time &&
                            now.isAfter(instance.warnTime)) {
                            store.commit('setShowRefreshBN', "timeoutWarn")
                        }
                    }
                }

                , 1000)

            // 5 min
            const keepAliveTimer = setInterval(() => {
                if (
                    !instance.lastRequest.isSame(instance.lastInteraction) &&
                    instance.lastInteraction.isAfter(dayjs().subtract(5, 'minute')) &&
                    store.state.user &&
                    !gettingTime
                ) {
                    keepAlive()
                }
            }, 5 * 60 * 1000)

            // METHODS
            // api
            instance.newRequest = function () {
                if (instance.time) {
                    if (instance.lastRequest && instance.time && dayjs().isAfter(instance.lastRequest.add(instance.time, 'milliseconds'))) {
                        logout()
                    } else {
                        if (store.getters.refreshBN === "timeoutWarn") {

                            store.commit('setShowRefreshBN', false)
                        }
                    }
                }

                instance.lastRequest = dayjs()
                instance.lastInteraction = dayjs()
                instance.logoutTime = dayjs().add(instance.time - 5000, 'millisecond')
                instance.warnTime = dayjs().add(instance.time - 120000, 'millisecond')
            }

            // mouse click / keystroke
            instance.newInteraction = function () {
                instance.lastInteraction = dayjs()
            }
        }
        return instance;
    }

})()
